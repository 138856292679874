/* Button block
--------------------------------------------- */
@import '../../mixins';

/**
 * 
 * CSS for both Frontend+Backend.
 * 
 */

$btn-colors: (
  'primary': $uwkc-a11y-red, 
  'secondary': $uwkc-blue, 
  'tertiary': $uwkc-blue-light, 
);

//default button styles
.wp-block-button__link,
.wp-block-button__link:visited{
    width:100%;
    max-width: 250px;
    min-width: 250px;
    transition: all 0.25s ease-in-out;
    border-radius: 5px;
    padding: .7em .5em;
    color:$uwkc-blue;
    border: 3px solid $uwkc-blue;  
    background:#fff; 
    box-shadow: 1px 1px 1px rgba(0,0,0,.5);  
    font-family: $font-TG-bold;
    letter-spacing: .5px;
}

.wp-block-button__link:hover,
.wp-block-button__link:focus{
  background:$uwkc-blue; 
  color:#fff;
  border-style:double;
  box-shadow: none;
}

//place holder text in editor for default button
.wp-block-button:not(.has-text-color):not(.is-style-outline) .editor-rich-text__tinymce[data-is-placeholder-visible=true]+.editor-rich-text__tinymce{
  color:$uwkc-blue;
}


// add color to text or background when button style selected in Gutenberg editor
@each $name, $color in $btn-colors {
.wp-block-button.is-style-#{$name}-outline .wp-block-button__link,
.wp-block-button.is-style-#{$name}-outline .wp-block-button__link:visited,
.wp-block-button.is-style-#{$name}-outline .editor-rich-text .components-autocomplete .wp-block-button__link.editor-rich-text__tinymce{
    border: 3px solid $color;
    border-style:double;
    background-color: #fff;
    color:$color;
  }

.wp-block-button.is-style-#{$name}-outline .wp-block-button__link:hover,
.wp-block-button.is-style-#{$name}-outline .wp-block-button__link:focus,
.wp-block-button.is-style-#{$name}-outline .editor-rich-text .components-autocomplete .wp-block-button__link.editor-rich-text__tinymce:hover,
.wp-block-button.is-style-#{$name}-outline .editor-rich-text .components-autocomplete .wp-block-button__link.editor-rich-text__tinymce:focus{
    background-color: $color;
    color:$uwkc-white;
    @include text-shadow(darken($color,25%));
    text-decoration: none;
  }

 .wp-block-button.is-style-#{$name}-fill .wp-block-button__link,
 .wp-block-button.is-style-#{$name}-fill .wp-block-button__link:visited,
 .wp-block-button.is-style-#{$name}-fill .editor-rich-text .components-autocomplete .wp-block-button__link.editor-rich-text__tinymce{
    border: 3px solid $color;
    background-color: $color;
    color:$uwkc-white;
    @include text-shadow(darken($color,25%));
  }

   .wp-block-button.is-style-#{$name}-fill .wp-block-button__link:hover,
   .wp-block-button.is-style-#{$name}-fill .wp-block-button__link:focus,
   .wp-block-button.is-style-#{$name}-fill .editor-rich-text .components-autocomplete .wp-block-button__link.editor-rich-text__tinymce:hover,
   .wp-block-button.is-style-#{$name}-fill .editor-rich-text .components-autocomplete .wp-block-button__link.editor-rich-text__tinymce:focus{
      background-color: $uwkc-white;
      color:$color;
      border-style:double;
      text-shadow: none;
      text-decoration: none;
  }
}


/* Align button */
.wp-block-button {
    text-align: center;
}
.wp-block-button.alignleft{
  @include mq(tablet, min){
      text-align: center;
      text-align: left;
  }
}

.wp-block-button.alignright{
    text-align: center;
    @include mq(tablet, min){
      text-align: right;
  }
}

.wp-block-button.aligncenter{
  text-align: center;
}